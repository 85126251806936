import Cookies from "universal-cookie";
import React, { useEffect } from 'react';
import Config from "../Config";

export const Logout = () => {
    const cookies = new Cookies();
    const apiUrl = Config();
    useEffect(() => {
        const logout = async () => {
            // Clear the token from local storage
            cookies.remove('token', { path: '/' });
            cookies.remove('name', { path: '/' });
            cookies.remove('email', { path: '/' });

            // Redirect to the home page
            if (localStorage.getItem('token')) {
                const response = await fetch(apiUrl +'/logout/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: cookies.get('email'),
                        token: cookies.get('token'),
                    })
                });
                window.location.href = '/login';
            } else {
                window.location.href = '/login';
            }
        }

        logout();
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    return (
        <></>
    );
}