import {Card, Typography} from "@material-tailwind/react";
import DocumentMeta from 'react-document-meta';
import React from "react";
import {Nav} from "../Nav";
import Cookies from "universal-cookie";

export const Products = () => {

    const meta = {
        title: 'Products - Jouw Applicatie',
        description: 'Jouw Applicatie, de plek waar jij OorlogSimulatie, Kingdom of andere plugins kan kopen! jouwapplicatie.nl',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'jouwapplicatie, jouw, applicatie'
            }
        }
    }

    const cookies = new Cookies();

    return (
        <DocumentMeta {...meta}>
            <Nav/>
            <div>
                <section id="Projects"
                         className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">
                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(cookies.get('token') != null ? "https://buy.stripe.com/8wM17w43Z5Qh9nW289" : "/login")}>
                            <img
                                src="https://assetsio.reedpopcdn.com/minecraft-medieval-castle.jpg?width=1200&height=600&fit=crop&enable=upscale&auto=webp"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.16.4 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">Kingdom Plugin</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$14.95</p>
                                    <del>
                                        <p className="text-sm text-gray-600 cursor-auto ml-2">€25</p>
                                    </del>
                                    <div className="ml-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd"
                                                  d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>
                                            <path
                                                d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(cookies.get('token') != null ? "https://buy.stripe.com/4gw2bA43Z1A143C4gp" : "/login")}>
                            <img
                                src="https://proxy.builtbybit.com/ae1a2a161f29678ad9bc54ef95957198959642ff?url=https%3A%2F%2Fi.gyazo.com%2F845136f096be00c485824cbf4ad10c00.jpg"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.8.9 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">OorlogSimulatie
                                    Plugin</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$19.95</p>
                                    <del>
                                        <p className="text-sm text-gray-600 cursor-auto ml-2">$30</p>
                                    </del>
                                    <div className="ml-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd"
                                                  d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>
                                            <path
                                                d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
                        <a href={(cookies.get('token') != null ? "https://buy.stripe.com/fZeg2q9oj4MdgQo9AK" : "/login")}>
                            <img
                                src="https://i.imgur.com/nORTHsC.png"
                                alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>
                            <div className="px-4 py-3 w-72">
                                <span className="text-gray-400 mr-3 uppercase text-xs">Minecraft 1.8.9 / 1.20.2</span>
                                <p className="text-lg font-bold text-black truncate block capitalize">OorlogSimulatie
                                    Private</p>
                                <div className="flex items-center">
                                    <p className="text-lg font-semibold text-black cursor-auto my-3">$34.95</p>
                                    <div className="ml-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                             fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd"
                                                  d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>
                                            <path
                                                d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    {/*<div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">*/}
                    {/*    <a href="#">*/}
                    {/*        <img*/}
                    {/*            src="https://images.unsplash.com/photo-1651950540805-b7c71869e689?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8Mjl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"*/}
                    {/*            alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>*/}
                    {/*        <div className="px-4 py-3 w-72">*/}
                    {/*            <span className="text-gray-400 mr-3 uppercase text-xs">Brand</span>*/}
                    {/*            <p className="text-lg font-bold text-black truncate block capitalize">Product Name</p>*/}
                    {/*            <div className="flex items-center">*/}
                    {/*                <p className="text-lg font-semibold text-black cursor-auto my-3">$149</p>*/}
                    {/*                <del>*/}
                    {/*                    <p className="text-sm text-gray-600 cursor-auto ml-2">$199</p>*/}
                    {/*                </del>*/}
                    {/*                <div className="ml-auto">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"*/}
                    {/*                         fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">*/}
                    {/*                        <path fillRule="evenodd"*/}
                    {/*                              d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>*/}
                    {/*                        <path*/}
                    {/*                            d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>*/}
                    {/*                    </svg>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    {/*<div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">*/}
                    {/*    <a href="#">*/}
                    {/*        <img*/}
                    {/*            src="https://images.unsplash.com/photo-1649261191624-ca9f79ca3fc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NDd8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"*/}
                    {/*            alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>*/}
                    {/*        <div className="px-4 py-3 w-72">*/}
                    {/*            <span className="text-gray-400 mr-3 uppercase text-xs">Brand</span>*/}
                    {/*            <p className="text-lg font-bold text-black truncate block capitalize">Product Name</p>*/}
                    {/*            <div className="flex items-center">*/}
                    {/*                <p className="text-lg font-semibold text-black cursor-auto my-3">$149</p>*/}
                    {/*                <del>*/}
                    {/*                    <p className="text-sm text-gray-600 cursor-auto ml-2">$199</p>*/}
                    {/*                </del>*/}
                    {/*                <div className="ml-auto">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"*/}
                    {/*                         fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">*/}
                    {/*                        <path fillRule="evenodd"*/}
                    {/*                              d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>*/}
                    {/*                        <path*/}
                    {/*                            d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>*/}
                    {/*                    </svg>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                    {/*<div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">*/}
                    {/*    <a href="#">*/}
                    {/*        <img*/}
                    {/*            src="https://images.unsplash.com/photo-1649261191606-cb2496e97eee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwcm9maWxlLXBhZ2V8NDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60"*/}
                    {/*            alt="Product" className="h-80 w-72 object-cover rounded-t-xl"/>*/}
                    {/*        <div className="px-4 py-3 w-72">*/}
                    {/*            <span className="text-gray-400 mr-3 uppercase text-xs">Brand</span>*/}
                    {/*            <p className="text-lg font-bold text-black truncate block capitalize">Product Name</p>*/}
                    {/*            <div className="flex items-center">*/}
                    {/*                <p className="text-lg font-semibold text-black cursor-auto my-3">$149</p>*/}
                    {/*                <del>*/}
                    {/*                    <p className="text-sm text-gray-600 cursor-auto ml-2">$199</p>*/}
                    {/*                </del>*/}
                    {/*                <div className="ml-auto">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"*/}
                    {/*                         fill="currentColor" className="bi bi-bag-plus" viewBox="0 0 16 16">*/}
                    {/*                        <path fillRule="evenodd"*/}
                    {/*                              d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>*/}
                    {/*                        <path*/}
                    {/*                            d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>*/}
                    {/*                    </svg>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </a>*/}
                    {/*</div>*/}

                </section>

            </div>
        </DocumentMeta>
    )
}