import { Sword, MagicWand, Info } from "phosphor-react";
import {React, useEffect} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Config from "../../Config";
import Cookies from "universal-cookie";
import axios from "axios";

export const Modal = ({showModal, closeModal, kingdom, warsimulation}) => {
    const apiUrl = Config();
    const cookies = new Cookies();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.id === 'default-modal') {
                closeModal();
            }
        };

        if (showModal) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showModal, closeModal]);

    const showError = (message, redirect) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 3000
        });
        if (redirect) {
            setTimeout(() => {
                window.location.href = '/account';
            }, 3000);
        }
    };

    const showSuccess = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 3000
        });
    }

    const handleDownload = async (product, version) => {
        try {
            const response = await axios.get(apiUrl + "/download/" + product + "/" + version, {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + cookies.get('token')
                },
            });

            // Als de statuscode 200 is, wordt het bestand gedownload
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Kingdom-${version}.jar`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                showSuccess("The file " + product + "-" + version + ".jar is now being downloaded.");
            } else {
                // Als de statuscode geen 200 is, wordt een foutmelding weergegeven
                console.error('Error downloading file. Server returned:', response.status);
                showError("You can't download this file if you have not purchased it.");
            }
        } catch (error) {
            // Als er een fout optreedt bij het maken van de request, wordt een foutmelding weergegeven
            console.error('Error downloading file:', error);
            showError("You can't download this file.");
        }
    }

    if (!showModal) return null;

    if (kingdom) {
        //kingdom
        return (
            <div id="default-modal" tabIndex="-1" aria-hidden="true"
                 className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-50 flex justify-center items-center">
                <ToastContainer />
                <div className="relative p-4 w-full max-w-2xl">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 border-b">
                            <div className="flex items-center space-x-2">
                                <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                    <MagicWand size={25} />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900">Kingdom Plugin - Versions & Downloads</h3>
                            </div>
                            <button type="button" onClick={closeModal} className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Version Name
                                        </th>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Minecraft Version
                                        </th>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Downloads
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">Kingdom</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.19 - 1.20.2</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button"
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">Kingdom</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.16 (1.16.5)</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button" onClick={() => handleDownload("KingdomPlugin", "1.16")}
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">Kingdom</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.12 (1.12.2)</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button"
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p className="text-sm text-gray-500 mt-4">
                                    ℹ If you find any bugs or a version that doesn't work, contact us on&nbsp;
                                    <a href="https://discord.gg/ve8XjJDvxe"
                                       target="_blank" rel="noopener noreferrer" className="cursor-pointer underline text-purple-500 hover:text-purple-800">Discord</a>
                                    .
                                </p>
                                <p className="text-sm text-red-500">
                                    ❗Note that you need to install the&nbsp;
                                    <a href="https://www.spigotmc.org/resources/placeholderapi.6245/"
                                       target="_blank" rel="noopener noreferrer" className="cursor-pointer underline text-blue-500 hover:text-blue-800">libaries</a>
                                    &nbsp;for this plugin to work.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        //warsimulation
        return (
            <div id="default-modal" tabIndex="-1" aria-hidden="true"
                 className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-50 flex justify-center items-center">
                <div className="relative p-4 w-full max-w-2xl">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 border-b">
                            <div className="flex items-center space-x-2">
                                <div className="w-8 h-8 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                    <Sword size={25} />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-900">WarSimulation Plugin - Versions & Downloads</h3>
                            </div>
                            <button type="button" onClick={closeModal} className="text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Version Name
                                        </th>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Minecraft Version
                                        </th>
                                        <th scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Downloads
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">OorlogSimulatie-2.3</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.19 - 1.20.2</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button"
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">OorlogSimulatie-2.3</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.16 (1.16.5)</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button"
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">OorlogSimulatie-2.3</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">1.12 (1.12.2)</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <button type="button"
                                                    className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-xs px-2 py-1.5 text-center w-24 me-2 mb-1">
                                                Download
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p className="text-sm text-gray-500 mt-4">
                                    If you find any bugs or a version that doesn't work, contact us on&nbsp;
                                    <a href="https://discord.gg/ve8XjJDvxe"
                                       target="_blank" rel="noopener noreferrer" className="cursor-pointer underline text-purple-500 hover:text-purple-800">Discord</a>
                                    .
                                </p>
                                <p className="text-sm text-red-500">
                                    ❗Note that you need to install the&nbsp;
                                    <a href="https://www.spigotmc.org/resources/placeholderapi.6245/"
                                       target="_blank" rel="noopener noreferrer" className="cursor-pointer underline text-blue-500 hover:text-blue-800">PlaceholderAPI</a>
                                    &nbsp;and <a href="https://dev.bukkit.org/projects/multiverse-core/files"
                                                 target="_blank" rel="noopener noreferrer" className="cursor-pointer underline text-blue-500 hover:text-blue-800">Multiverse-Core</a>
                                    &nbsp;for this plugin to work.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};