import React, {useState} from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import Cookies from "universal-cookie";
import { Sword, MagicWand } from "phosphor-react";
import {Modal} from "./extra/Modal";

export const Services = () => {
    const [showModal, setShowModal] = useState(false);
    const [kingdomModal, setKingdomModal] = useState(false);
    const [warModal, setWarModal] = useState(false);
    const cookies = new Cookies();
    const imageUrl = "https://i.imgur.com/VePkpuk.png";
    const imageUrl2 = "https://i.imgur.com/hTKxamE.png";

    const setKingdom = () => {
        setWarModal(false);
        setShowModal(true);
        setKingdomModal(true);
    }

    const setWar = () => {
        setKingdomModal(false);
        setShowModal(true);
        setWarModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    };

    if (!cookies.get("token")) {
        window.location.href = "/login";
    } else {
        return (
            <div className="bg-gray-100 min-h-screen">
                <Sidebar>
                    <Modal showModal={showModal} closeModal={closeModal} kingdom={kingdomModal} warsimulation={warModal} />
                    <div className="container mx-auto px-4">
                        <h1 className="text-3xl font-bold text-center mt-8 mb-4">My services</h1>
                        <p className="text-lg text-center text-gray-700 mb-8">
                            View all products or services that you have purchased or had made by us.</p>
                    </div>

                    <div className="flex flex-wrap justify-center mt-10">
                        {/* Kaart 1 */}
                        <div className="p-4 max-w-sm w-full md:w-1/3">
                            <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col h-full">
                                {/* Afbeelding */}
                                <img
                                    src={imageUrl}
                                    alt="Afbeelding"
                                    className="w-full h-32 object-cover rounded-t-lg mb-4"
                                />
                                {/* Kaart */}
                                <div className="p-6 flex flex-col justify-between flex-grow">
                                    <div className="flex items-center mb-3">
                                        <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <MagicWand size={25} />
                                        </div>
                                        <h2 className="text-gray-800 dark:text-white text-lg font-bold font-cinzel">
                                            Kingdom Plugin
                                        </h2>
                                    </div>
                                    <p className="leading-relaxed text-base text-gray-800 dark:text-gray-300">
                                        The Kingdom plugin is officially based on the plugin of
                                        the Kingdom server by DusDavidGames, who is also the
                                        creator of the Kingdom concept. In this plugin, you can
                                        find almost everything you need! If you click on More
                                        info & Download, you can find more info about this plugin
                                        and get the version you like.
                                    </p>
                                    <a
                                        className="mt-3 text-black dark:text-white inline-flex items-center hover:text-blue-600"
                                        onClick={() => setKingdom()}
                                    >
                                        <div>More info & Download</div>
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-4 h-4 ml-2"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Kaart 2 */}
                        <div className="p-4 max-w-sm w-full md:w-1/3">
                            <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col h-full">
                                {/* Afbeelding */}
                                <img
                                    src={imageUrl2}
                                    alt="Afbeelding"
                                    className="w-full h-32 object-cover rounded-t-lg mb-4"
                                />
                                {/* Kaart */}
                                <div className="p-6 flex flex-col justify-between flex-grow">
                                    <div className="flex items-center mb-3">
                                        <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                                            <Sword size={25} />
                                        </div>
                                        <h2 className="text-gray-800 dark:text-white text-lg font-bold font-cinzel">
                                            WarSimulation Plugin
                                        </h2>
                                    </div>
                                    <p className="leading-relaxed text-base text-gray-800 dark:text-gray-300">
                                        The "war simulation" plugin for Minecraft is an addition
                                        that enables players to simulate advanced war and combat
                                        scenarios within the Minecraft world. This plugin offers
                                        various functionalities depending on how it is designed
                                        and implemented.
                                    </p>
                                    <a
                                        className="mt-3 text-black dark:text-white inline-flex items-center hover:text-blue-600"
                                        onClick={() => setWar()}
                                    >
                                        <div>More info & Download</div>
                                        <svg
                                            fill="none"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            className="w-4 h-4 ml-2"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
};
