import React, { useEffect, useState } from "react";
import { Sidebar } from "../../Sidebar";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Cardholder } from "phosphor-react";
import Config from "../../../Config";
import Cookies from "universal-cookie";

export const Users = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10; // Aantal items per pagina
    const apiUrl = Config();
    const cookies = new Cookies();

    // Haal gebruikersgegevens op bij het laden van de pagina
    useEffect(() => {
        fetchAllUsers();
    }, []);

    // Functie om de pagina te wijzigen
    const handlePageChange = (pageNumber) => {
        const endI = Math.ceil(filteredUsers.length / itemsPerPage); // Bereken de index van de laatste pagina
        if (pageNumber > 0 && pageNumber <= endI) { // Controleer of de pagina binnen de grenzen ligt
            setActivePage(pageNumber);
        }
    };

    // Filter de gebruikers op basis van de zoekterm en de actieve pagina
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const usersToShow = filteredUsers.slice(startIndex, endIndex);

    // Haal alle gebruikers op
    const fetchAllUsers = async () => {
        try {
            const result = await axios.get(
                apiUrl + '/users/all',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + cookies.get('token')
                    }
                }
            );

            if (result.status === 403) {
                console.log('Forbidden access');
                showError("Je hebt geen toegang tot deze pagina.", true);
            } else {
                setUsers(result.data);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            if (error.response && error.response.status === 403) {
                console.log('Forbidden access');
                showError("Je hebt geen toegang tot deze pagina.", true);
            }
        }
    }

    const showError = (message, redirect) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 3000
        });
        if (redirect) {
            setTimeout(() => {
                window.location.href = '/account';
            }, 3000);
        }
    };

    const makeAdmin = async (user) => {
        console.log("makeAdmin method");

        const response = await fetch(apiUrl + '/users/promote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + cookies.get('token')
            },
            body: JSON.stringify({
                email: user.email,
            })
        });
        const responseData = await response.json();
        if (response.status === 404)
            showError("Deze user kon niet gevonden worden.");
        if (response.status === 403)
            showError("Je hebt geen toestemming voor deze actie.");
        if (response.status === 409)
            showError("Deze gebruiker is al een admin.");
        if (response.ok) {
            fetchAllUsers();
            showSuccess("Je hebt de gebruiker " + responseData.user.firstname + " " + responseData.user.lastname + " als admin gemaakt!");
        } else {
            console.log("Fout: " + responseData.message);
            console.log("Foutjes: " + responseData);
        }
    }

    const showSuccess = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 3000
        });
    }

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    } else {
        return (
            <Sidebar>
                <ToastContainer />
                <div className="max-w-screen-lg mx-auto px-4 sm:px-4">
                    <div className="overflow-x-auto flex flex-col sm:flex-row">
                        <div className="shadow-md my-2 border-b border-gray-200 sm:rounded-lg w-full sm:w-3/4">
                            <label htmlFor="table-search" className="sr-only">🔍 Zoeken..</label>
                            <div className="mt-1">
                                <input type="text" id="table-search"
                                       value={searchTerm}
                                       onChange={handleSearch}
                                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                       placeholder="🔍 Zoeken naar gebruikers.."/>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 font-bold">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold">
                                            Role
                                        </th>
                                        <th scope="col" className="px-6 py-3 font-bold text-right">
                                            🎓
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {usersToShow.map((user, index) => (
                                        <tr key={index}
                                            className={`bg-white ${user.firstname === 'Anoniem' ? 'text-gray-400' : 'text-gray-900'}`}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">{user.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">{user.email}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">{user.role.toUpperCase()}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
            <span
                className={`${user.name === 'Anoniem' ? 'opacity-50 cursor-not-allowed' : 'text-blue-600 dark:text-blue-500 hover:underline cursor-pointer'}`}
                onClick={user.name !== 'Anoniem' ? () => makeAdmin(user) : null}>
                Make admin
            </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className="flex">
                        <a onClick={() => handlePageChange(activePage - 1)}
                           className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
                            </svg>
                            Previous
                        </a>
                        <a onClick={() => handlePageChange(activePage + 1)}
                           className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                            <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                      strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </a>
                    </div>
                    {/*d*/}
                    {/*<div className="flex">*/}
                    {/*    <a href="#"*/}
                    {/*       className="flex items-center justify-center px-4 h-10 me-3 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">*/}
                    {/*        <svg className="w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">*/}
                    {/*            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                  strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>*/}
                    {/*        </svg>*/}
                    {/*        Previous*/}
                    {/*    </a>*/}
                    {/*    <a href="#"*/}
                    {/*       className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">*/}
                    {/*        Next*/}
                    {/*        <svg className="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">*/}
                    {/*            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                  strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>*/}
                    {/*        </svg>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </Sidebar>
        )
    }
}
