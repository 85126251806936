import {Link} from 'react-router-dom';
import React, {useRef, useState, useEffect} from 'react';
import {List, X} from "phosphor-react";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import {
    InboxIcon,
    PowerIcon,
} from "@heroicons/react/24/solid";

export const Sidebar = ({children}) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const buttonRef = useRef(null);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {

        if (buttonRef.current) {
            tippy(buttonRef.current, {
                content: 'Sluit de zijbalk',
            });
        }

        const handleResize = () => {
            setSidebarOpen(window.innerWidth > 540);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<>
        {!isSidebarOpen && (
            <>
                <div onClick={toggleSidebar} className="hide-on-large-screen cursor-pointer fixed top-0 left-0 flex items-center justify-center p-2 z-50">
                    <button className="ml-1 m-1 bg-gray-800 rounded-lg p-2 focus:outline-none hover:bg-gray-700 transition-colors duration-300">
                        <List size={32} className="text-white" />
                    </button>
                </div>
                <div className="h-16"></div> {/* Whitespace onder de toggleSidebar */}
            </>
        )}

        <div className="z-10">
            <div
                className={`fixed flex flex-col top-0 left-0 w-64 bg-gray-800 h-full border-r transition-all duration-300 ${
                    isSidebarOpen ? '' : '-translate-x-full'
                }`}
            >
                <Link to="/">
                    <div
                        className="flex items-center justify-center h-14 border-b text-xl font-semibold text-white"
                        style={{ borderBottomColor: "grey" }}
                    >
                        JouwApplicatie
                    </div>
                </Link>


                <div className="overflow-y-auto overflow-x-hidden flex-grow">
                    <ul className="flex flex-col py-4 space-y-1">
                        <li className="px-5">
                            <div className="flex flex-row items-center h-8">
                                <div className="text-sm tracking-wide text-white font-bold">You</div>
                            </div>
                        </li>
                        <li className="group">
                            <Link to="/account/services"
                                  className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-black border-l-4 border-transparent hover:border-indigo-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4 group-hover:text-black">
                        <InboxIcon className="h-5 w-5"/>
                    </span>
                                <span
                                    className="ml-2 text-sm tracking-wide truncate font-medium text-white group-hover:text-black">My Services</span>
                            </Link>
                        </li>

                        <li className="border-b border-gray-400 h-4"></li>
                        <li className="px-5">
                            <div className="flex flex-row items-center h-8">
                                <div className="text-sm tracking-wide text-white font-bold">Settings</div>
                            </div>
                        </li>
                        <li className="group">
                            <Link to="/logout"
                                  className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-black border-l-4 border-transparent hover:border-indigo-500 pr-6">
                    <span className="inline-flex justify-center items-center ml-4 group-hover:text-black">
                        <PowerIcon className="h-5 w-5"/>
                    </span>
                                <span
                                    className="ml-2 text-sm tracking-wide truncate font-medium text-white group-hover:text-black">Logout</span>
                            </Link>
                        </li>
                    </ul>

                </div>

                <div className="p-7">
                    <div className="hide-on-large-screen absolute bottom-0 right-0">
                        <button
                            ref={buttonRef}
                            onClick={toggleSidebar}
                            className="p-2 focus:outline-none text-white relative"
                        >
                            <X size={20} />
                        </button>
                    </div>
                </div>

            </div>

            {isSidebarOpen && (
                <div className="p-4 sm:ml-64 mt-10 bg-gray-100 min-h-screen">{children}</div>
            )}
            {!isSidebarOpen && (
                <div>{children}</div>
            )}
        </div>


    </>);
}
