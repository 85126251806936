import React from "react";
import {Sidebar} from "../dashboard/Sidebar";
import Cookies from "universal-cookie";
import {Hero} from "../dashboard/extra/Hero";

export const ProfileDashboard = () => {
    const cookies = new Cookies();
    if (!cookies.get('token')) {
        window.location.href = '/login';
    }else{
        return (
            <div className="flex">
                <Sidebar>
                    <Hero/>
                </Sidebar>
            </div>
        );
    }
}